.main-wrapper {
  .cohort-heading {
    color: #262626;
    font-family: Outfit;
    font-size: 45px;
    font-style: normal;
    padding-top: "23px";
    font-weight: 600;
    line-height: normal;
    margin: 0;
    // margin-left: 2%;
    // margin-bottom: 30px;
    // margin-top: 30px;
  }

  .main-container {
    padding: 2% 2% 2% 2%;
    margin-top: 30px;
 

    .first-section {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .span-container {
        display: flex;
        gap: 50px;
        align-items: center;
        justify-content: flex-end;
        width: 500px;

        .price-span {
          font-family: Outfit;
          font-size: 20px;
          font-weight: 500;

          background: var(--Linear,
              linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      h2 {
        color: #262626;
        font-family: Outfit;
        font-size: 24px;
        font-weight: 600;
        margin: 0;
      }

      span {
        color: #523168;
        font-size: 20px;
        font-weight: 500;
      }
    }

    p {
      color: rgba(32, 33, 35, 0.87);
      font-family: Outfit;
      font-size: 16px;
      font-weight: 300;
      line-height: 23px;
      height: 50px;
    }

    h3 {
      color: var(--a, #523168);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 116.7%;
      margin: 0;
      height: 50px;
      display: flex;
      align-items: end;
    }

    
 

  

   
    .third-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @media screen and (max-width: 568px) {
           
        margin-top: 55px;
      
    }

      .inner-container {
        display: flex;
        gap: 4rem;
        align-items: center;
        height: 170px;
        @media screen and (max-width: 700px) {
           
            margin-top: 55px;
          
        }

        @media screen and (max-width: 1366px) {
          .inner-container {
            gap: 2rem;
          }
        }

     

        h4 {
          display: flex;
          flex-direction: column;

          span {
            color: rgba(8, 5, 21, 0.6);
            font-family: Outfit;
            font-size: 18px;
            font-weight: 400;
            line-height: 130%;
            @media screen and (max-width: 700px) {
           
            font-size: 13px;
            
          }
  
          }

          h5 {
            margin-top: 10px;
            color: var(--Heading, #080515);
            font-family: Outfit;
            font-size: 20px;
            font-weight: 600;
            line-height: 130%;
          }
        }

        @media screen and (max-width: 1366px) {
          inner-container {
            gap: 2rem;
          }
        }
      }
    }
  }
}

// .main-container:hover {
//   background-color: #f8f8f8;
// }

@media screen and (max-width: 568px) {
  .cohort-heading {
    color: #262626;
    font-family: Outfit;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }

  .first-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    h2 {
      color: #262626;
      font-family: Outfit;
      font-size: 24px;
      font-weight: 600;
      margin: 0;
    }

    span {
      color: #523168;
      font-size: 20px;
      font-weight: 500;
    }
  }

  p {
    color: rgba(32, 33, 35, 0.87);
    font-family: Outfit;
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
  }

  h3 {
    color: var(--a, #523168);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 116.7%;
    margin: 0;
  }

  .third-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;

    .inner-container {
      display: flex;
      gap: 20px !important;
      align-items: center;

      h4 {
        display: flex;
        flex-direction: row;

        span {
          color: rgba(8, 5, 21, 0.6);
          font-family: Outfit;
          font-size: 16px !important;
          font-weight: 400;
          line-height: 130%;
        }

        h5 {
          margin-top: 10px;
          color: var(--Heading, #080515);
          font-family: Outfit;
          font-size: 14px !important;
          font-weight: 400;
          line-height: 130%;
        }
      }
    }
  }
}