.spinner {
  text-align: center;
}
.textAreaContainer {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  textarea {
    height: 100px;
  }
  input {
    height: 10px;
  }
  .micIcon {
    color: #c7340d;
    position: absolute;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
  }
}
/* common.scss */

.dropDownContainer {
  margin-bottom: 20px;
}

.heading {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

select {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

span.error {
  color: red;
  font-size: 12px;
}

.checkboxAreaContainer {
  .checkboxWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    label {
      color: rgba(0, 0, 0, 0.87);
      font-size: 15px;
      font-weight: 400;
    }
  }
}
.heading {
  color: #161e29;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  margin: 30px 0 7px 0;
}
textarea,
input {
  padding: 18px;
  border-radius: 10px;
  vertical-align: top;
  overflow-y: auto;
  resize: none;
  border: 2px solid #9783a4;
  // height: 27px !important;
}

input [type="text"] {
  padding: 18px;
  width: 100%;
  border-radius: 10px;
  vertical-align: top;
  overflow-y: auto;
  resize: none;
  border: 2px solid #9783a4;
}
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: #523168;
}

textarea:focus {
  outline: none;
}
input:focus {
  outline: none;
}
.recordingOverlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  width: 600px;
  height: 300px;

  @-webkit-keyframes pulsate {
    0% {
      -webkit-transform: scale(1, 1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.3, 1.3);
      opacity: 0;
    }
  }

  button {
    margin-top: auto;
    border: none;
    background: var(
      --Linear,
      linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
    );
    width: 30%;
    color: #fff;
    font-size: 1em;
    font-weight: 500;
    border-radius: 10px;
    cursor: pointer;
    z-index: 99;
    padding: 10px 25px;
  }
}
.paginationWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  div {
    border-radius: 8px;
    cursor: pointer;
    padding: 3px 12px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: #fff;
    color: rgba(32, 33, 35, 0.87);
    font-size: 14px;
    font-weight: 400;
  }
}
