.masterMindCommunityContainer {
  * {
    scrollbar-width: auto;
  }

  ::-webkit-scrollbar {
    width: 7px;
    background: #f1f1f1;
  }

  ::-moz-scrollbar {
    width: 7px;
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  background: #f8f8f8;

  .header {
    display: flex;
    align-items: center;
    gap: 19px;

    background: #fff;
    height: 80px;
    margin: 10px 0;
    h2 {
      color: #262626;
      font-family: Outfit;
      font-size: 28px;
      font-weight: 600;
      margin: 0;
    }
  }
  .chatContainer {
    display: flex;
    gap: 10px;

    .chatList {
      padding: 26px 15px;
      background: #fff;
      width: 27%;
      position: relative;
      .header {
        display: contents;
        padding: 10px 0;
        border-bottom: 1px solid #dcdcdc;
        h3 {
          color: #262a41;
          font-size: 20px;
          margin: 0 0 10px 0;
          font-weight: 600;
        }
      }

      .chats {
        display: flex;
        flex-direction: column;
        // gap: 10px;
        margin-top: 19px;
        background: #fff;
        overflow: auto;
        height: 73vh;
        div {
          display: flex;
          align-items: center;
          gap: 19px;
          background: var(
            --Linear,
            linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
          );
          padding: 5px 10px;
          border-radius: 10px;
          color: #262626;

          cursor: pointer;
          span {
            color: #262626;
            font-size: 24px;
            font-weight: 400;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #e8e8e8;
            border-radius: 50%;
          }
          p {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }

    .chatScreen {
      width: 50%;
      margin-top: -10px;
      .header {
        display: flex;
        gap: 20px;
        padding: 0 23px;
        span {
          color: #262626;
          font-size: 24px;
          font-weight: 400;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #e8e8e8;
          border-radius: 50%;
        }
        p {
          color: rgba(0, 0, 0, 0.87);
          font-size: 24px;
          font-weight: 500;
        }
      }
      .chatDetails {
        padding: 4px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-y: auto; /* Add scroll */
        // max-height: 550px;
        // min-height: 450px;
        height: 550px;

        .chatBubbleOther {
          background-color: #dfdbf9;
          padding: 10px 10px;
          width: 50%;
          border-radius: 10px;
          border-top-left-radius: 0px;
          margin-top: 10px;
          align-self: flex-start;
          position: relative;
          .bubbleChatDate {
            color: gray;
            font-size: 8px;
            position: absolute;
            right: 10px;
            bottom: 2px;
          }
        }
        .chatBubbleSelf {
          background-color: #f6e9e0;
          padding: 10px 10px;
          width: 50%;
          border-radius: 10px;
          border-top-right-radius: 0px;
          margin-top: 10px;
          align-self: flex-end;
          position: relative;
          .bubbleChatDate {
            color: gray;
            font-size: 8px;
            position: absolute;
            right: 10px;
            bottom: 2px;
          }
        }
      }
      .chatKeyboard {
        background: #fff;
        padding: 8px;
        height: 81px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 20px;
        position: relative;
        .keypad {
          display: flex;
          align-items: center;
          width: 100%;
          background-color: #f7f8fc;
          height: 100%;
          input {
            width: 100%;
            border: none;
            background: transparent;
          }
          div {
            background-color: #ff7f26;
            width: 85px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
        }
        .listening {
          animation-name: pulse;
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
        }

        @keyframes pulse {
          0% {
            transform: scale(1.7);
          }
          50% {
            transform: scale(2);
          }
          100% {
            transform: scale(2.1);
          }
        }
      }
    }
    .stasutScreen {
      width: 22%;
      margin-top: -10px;
      background-color: #fff;
      .header {
        display: grid;
        padding: 18px 18px 15px 18px;
        height: fit-content;
        border-bottom: 1px solid #e8e8e8;
        .inner {
          display: flex;
          align-items: center;
          gap: 11px;
          div {
            display: grid;
            align-items: center;
            h3 {
              margin: 0;
              color: #262a41;
              font-size: 20px;
              font-weight: 600;
              margin: 0;
            }
            p {
              margin: 0;
              color: rgba(0, 0, 0, 0.6);
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
        button {
          border-radius: 10px;
          border: 1px solid #e6e6e6;
          background: #fff;
          width: 100%;
          margin: 0 5% 0 auto;
          color: #0b5cff;
          font-size: 14px;
          font-weight: 600;
          padding: 10px 12px;
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        @media only screen and (max-width: 899px) {
          button {
            width: 100%; /* Adjust width for medium screens */
            /* Adjust font size for better readability */
          }
        }
      }
    }
    .listing {
      display: flex;
      flex-direction: column;
      // gap: 10px;
      justify-content: space-between;
      margin-top: 19px;
      background: #fff;
      overflow: auto;
      // height: 73vh;
      div {
        display: flex;
        align-items: center;

        gap: 19px;
        // background: var(
        //   --Linear,
        //   linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
        // );
        padding: 5px 10px;
        border-radius: 10px;
        color: #262626;

        cursor: pointer;
        span {
          color: #262626;
          font-size: 24px;
          font-weight: 400;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #e8e8e8;
          border-radius: 50%;
        }
        p {
          font-size: 20px;
          font-weight: 400;
          color: #00000099;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .chatContainer {
      flex-direction: column;
    }
    .chatList {
      width: 100% !important;
    }
    .chatScreen {
      width: 100% !important;
    }
    .stasutScreen {
      width: 100% !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .chatContainer {
      flex-direction: column;
    }
    .chatList {
      width: 100% !important;
    }
    .chatScreen {
      width: 100% !important;
    }
    .stasutScreen {
      width: 100% !important;
    }
  }
  @media only screen and (max-width: 513px) {
    h2 {
      font-size: 20px !important;
    }
  }
}
